/**
 * cart list item
 */
/* eslint-disable */
import React from 'react';
//connect to store
import { connect } from "react-redux";
//action
import { Link } from 'react-router-dom';
import pb from "../../../assets/images/point-buck1.png"
import { getCompanyDefaultGroup } from "../../../../Authorization";
import PointBuckSvgComponent from "../../../assets/svgcomponents/PointBuckSvgComponent";

class Notification extends React.Component {

   constructor(props) {
      super(props);
      this.confirmationDialog = React.createRef();
      this.state = {
         anchorEl: null,
         balance: "$0.00",
         showBucks: false,
         companyDefaultGroup: null
      };
   }

   componentDidMount() {
      this.setState({ companyDefaultGroup: getCompanyDefaultGroup() });
   }

   numberWithCommas = (value) => {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
   }


   formatCurrency = (value) => {
      return '$' + value != null ? value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : 0;
   }

   render() {
      const { balance, showBucks, showPoints, points, groupModel, appAttributes } = this.props;
      return (
         groupModel ?
            (showPoints && showBucks) ?
               <div className="point-buck">
                  {points.totalAvailable == 1 ? <Link className="point" to="/points">
                     <p><b>{this.numberWithCommas(
                        points.totalPoints
                     )}</b></p>
                     <span><b>Points</b></span>
                  </Link> : <a className="point" >
                     <p>Points</p>
                     <span><b>Coming Soon</b></span>
                  </a>}
                  <div>
                     <PointBuckSvgComponent height="52" width="44" active="var(--active)" />
                  </div>
                  <Link className="buck" to="/transaction">
                     <p><b> {balance}</b></p>
                     <span><b>{appAttributes && appAttributes.BUCKS_LABEL ? appAttributes.BUCKS_LABEL : "Bucks"} </b></span>
                  </Link>
               </div>
               : showPoints ?
                  <div className="point-buck half">
                     {points.totalAvailable == 1 ? <Link className="point" to="/points">
                        <p><b>{this.numberWithCommas(
                           points.totalPoints
                        )}</b></p>
                        <span><b>Points</b></span>
                     </Link> : <a className="point" >
                        <p>Points</p>
                        <span><b>Coming Soon</b></span>
                     </a>}
                  </div>
                  : showBucks ?
                     <div className="point-buck half">
                        <Link className="buck" to="/transaction">
                           <p><b> {balance}</b></p>
                           <span><b>{appAttributes && appAttributes.BUCKS_LABEL ? appAttributes.BUCKS_LABEL : "Bucks"} </b></span>
                        </Link>
                     </div>
                     : ""
            : ""
      );
   }
}

// map state to props
const mapStateToProps = ({ appSettings }) => {
   const { appAttributes } = appSettings;
   return { appAttributes };
}

export default connect(mapStateToProps, {
})(Notification);

