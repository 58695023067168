import React from "react";
import "./index.scss";

const SectionInformation = () => {
  return (
    <div className="information-section">
      {/* Large Banner Equivalent */}
      <div className="rectangle">Banner 1</div>
      <div className="rectangle">Banner 2</div>
      <div className="rectangle column-small">Banner 3</div>
    </div>
  );
};

export default SectionInformation;
