import { createSlice } from "@reduxjs/toolkit";

const homepageSlice = createSlice({
  name: "homepage",
  initialState: { data: [] },
  reducers: {
    getData(state, action) {
      state.data = action.payload;
    },
    addData(state, action) {
      state.data.push(action.payload);
    },
  },
});

export const { getData, addData } = homepageSlice.actions;
export default homepageSlice.reducer;
