import React, { Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getShoppingCartCount,
  removeProductItem,
  removeProductItemWithProductId,
} from "../../../actions/action";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import { local } from "constant";
import ConfirmationDialog from "../../global/confirmation-popup";
// import ContentLoader from '../../global/loaders/ContentLoader';
import CircularProgress from "@material-ui/core/CircularProgress";

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.confirmationDialog = React.createRef();
    this.state = {
      anchorEl: null,
    };
  }

  componentDidMount() {
    this.props.getShoppingCartCount();
  }

  //Define function for open dropdown
  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  //Define function for close dropdown
  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  //Function to delete product from cart
  onDeleteCartItem(cartItem) {
    this.cartItem = cartItem;
    this.confirmationDialog.current.openDialog();
  }
  //Function for delete cart product
  deleteCartItem(popupResponse) {
    if (popupResponse) {
      this.props.removeProductItemWithProductId({
        productId: this.cartItem.productId,
      });
    }
  }

  render() {
    const { anchorEl } = this.state;
    const { cartItemsCount, cart } = this.props;
    const total = cart.reduce((accumulator, object) => {
      return accumulator + object.price * object.quantity;
    }, 0);
    return (
      <div className="iron-cart-wrap">
        <IconButton
          component={Link}
          to="/cart"
          color="inherit"
          aria-haspopup="true"
          variant="contained"
          className={`icon-btn icon-cart-btn mr-10`}
          aria-label="Cart"
        >
          <Badge
            badgeContent={cart !== null && cart.length > 0 ? cartItemsCount : 0}
            color="secondary"
            className="badge-active"
          >
            <i className="material-icons">shopping_cart</i>
          </Badge>
          <Fragment>
            {cart !== null ? (
              cart.length > 0 ? (
                <div className="cart-popup">
                  <div>
                    <ul className="iron-cart-list-wrap mb-0">
                      {cart &&
                        cart.map((cartItem, index) => {
                          return (
                            <li
                              key={index}
                              className="cart-menu-item p-10 mb-0"
                            >
                              <div className="d-flex iron-cart-post">
                                <div className="cart-thumb">
                                  {cartItem && cartItem.image ? (
                                    <img
                                      src={local.imageUrl + cartItem.image}
                                      alt="cart-item"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className=" cart-content-wrap d-flex justify-content-start align-items-center">
                                  <div className="cart-content">
                                    <h6 className="mb-5 text-truncate">
                                      {cartItem.name}
                                    </h6>
                                  </div>
                                  <div className="cart-edit-action d-flex justify-content-end align-items-center">
                                    <Button
                                      className="icon-btn button mr-5"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.onDeleteCartItem(cartItem);
                                      }}
                                    >
                                      <i className="material-icons">
                                        remove_shopping_cart
                                      </i>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                    <div className=" py-15 px-10">
                      <Button
                        onClick={this.handleClose}
                        component={Link}
                        to="/cart"
                        className="button btn-active w-100"
                      >
                        {`Checkout | Total : $${total.toFixed(2)} `}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="empty-cart-popup">
                  <h1>
                    <img
                      src="https://vet-price-check.s3.us-west-2.amazonaws.com/vetpricescout/dashboard/MEDIA/empty-cart.png"
                      alt=""
                    />{" "}
                    Empty Cart
                  </h1>
                </div>
              )
            ) : (
              <div>
                <span className="text-capitalize text-14 dark-color d-block px-40 py-15">
                  <CircularProgress
                    size={30}
                    thickness={1}
                    style={{ color: "var(--active)" }}
                  />
                </span>
              </div>
            )}
            <ConfirmationDialog
              ref={this.confirmationDialog}
              msg="Do you want to delete this products from cart?"
              onConfirm={(res) => this.deleteCartItem(res)}
            />
          </Fragment>
        </IconButton>
      </div>
    );
  }
}

// map state to props
const mapStateToProps = ({ ecommerce }) => {
  const { cartItemsCount, cart } = ecommerce;
  return { cartItemsCount, cart };
};

export default connect(mapStateToProps, {
  getShoppingCartCount,
  removeProductItem,
  removeProductItemWithProductId,
})(Cart);
