import React, { useState } from "react";
import Menu from "../Menu/Menu";
import "./Header.scss";

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      <header className="vetscout-header">
        <div className="header-container">
          <div className="logo">Logo</div>

          <div className="search-container">
            <div className="search-box">
              <input
                type="text"
                className="search-input"
                placeholder="Search by product name, SKU, Manufacturer or Vendors"
              />
            </div>

            <input
              type="text"
              className="search-input"
              placeholder="Change Practice"
            />
          </div>

          <div className="header-icons">
            <button className="cart-button">
              <div className="cart-text">Cart</div>
              <span className="cart-count">1</span>
            </button>

            <button className="profile">
              <div className="profile-icon">CA</div>
            </button>
          </div>
        </div>
      </header>
      <Menu />
    </div>
  );
};

export default Header;
