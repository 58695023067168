import React from "react";
import Loadable from "react-loadable";

import ContentLoader from "../components/global/loaders/ContentLoader";

const AsyncMyProductsComponent = Loadable({
  loader: () => import("../routes/products/my-products"),
  loading: () => <ContentLoader />,
});

const AsyncMyFavouritesComponent = Loadable({
  loader: () => import("../routes/products/my-favourites"),
  loading: () => <ContentLoader />,
});

const AsyncMyShoppingListsComponent = Loadable({
  loader: () => import("../routes/products/my-shopping-list"),
  loading: () => <ContentLoader />,
});

const AsyncPSIPromotionComponent = Loadable({
  loader: () => import("../routes/exclusive-promotions"),
  loading: () => <ContentLoader />,
});

const AsyncVetscoutPromotionComponent = Loadable({
  loader: () => import("../routes/vetscout-exclusive"),
  loading: () => <ContentLoader />,
});

const AsyncPointBasedProductPromotionComponent = Loadable({
  loader: () => import("../routes/products/point-based-products"),
  loading: () => <ContentLoader />,
});
const AsyncPromotionComponent = Loadable({
  loader: () => import("../routes/products/promotion"),
  loading: () => <ContentLoader />,
});
const AsyncRebateComponent = Loadable({
  loader: () => import("../routes/rebate"),
  loading: () => <ContentLoader />,
});

// cart
const AsyncCartComponent = Loadable({
  loader: () => import("../routes/cart"),
  loading: () => <ContentLoader />,
});

// Notifications
const AsyncNotificationsComponent = Loadable({
  loader: () => import("../routes/notifications"),
  loading: () => <ContentLoader />,
});

const AsyncOrderComponent = Loadable({
  loader: () => import("../routes/orders"),
  loading: () => <ContentLoader />,
});

const AsyncInvoicesComponent = Loadable({
  loader: () => import("../routes/invoices"),
  loading: () => <ContentLoader />,
});

const AsyncBackorderedComponent = Loadable({
  loader: () => import("../routes/backordered"),
  loading: () => <ContentLoader />,
});

const AsyncOrderProductComponent = Loadable({
  loader: () => import("../routes/orderProduct"),
  loading: () => <ContentLoader />,
});

const AsyncOrderManufactureComponent = Loadable({
  loader: () => import("../routes/orderManufacture"),
  loading: () => <ContentLoader />,
});

// checkout
const AsyncCheckOutComponent = Loadable({
  loader: () => import("../routes/check-out"),
  loading: () => <ContentLoader />,
});

// user login
const UserLogin = Loadable({
  loader: () => import("../routes/userLogin"),
  loading: () => <ContentLoader />,
});
const ThirdPartyLogin = Loadable({
  loader: () => import("../routes/thirdPartyLogin"),
  loading: () => <ContentLoader />,
});

// psivet-transaction
const PsivetTransaction = Loadable({
  loader: () => import("../routes/hyperwallet"),
  loading: () => <ContentLoader />,
});

// blog detail
const AsyncBlogDetailComponent = Loadable({
  loader: () => import("../routes/blogs/detail"),
  loading: () => <ContentLoader />,
});
// viewProducts
const AsyncViewProductsComponent = Loadable({
  loader: () => import("../routes/viewProducts"),
  loading: () => <ContentLoader />,
});

// accessories
const AsyncAccessoriesComponent = Loadable({
  loader: () => import("../routes/categories/accessories"),
  loading: () => <ContentLoader />,
});

// contact
const AsyncContactUsComponent = Loadable({
  loader: () => import("../routes/contact"),
  loading: () => <ContentLoader />,
});

// Faq
const AsyncFAQComponent = Loadable({
  loader: () => import("../routes/faq"),
  loading: () => <ContentLoader />,
});

const AsyncManageOrder = Loadable({
  loader: () => import("../routes/manage-order"),
  loading: () => <ContentLoader />,
});

// page404
const AsyncPageNotFoundComponent = Loadable({
  loader: () => import("../routes/page-404"),
  loading: () => <ContentLoader />,
});
// error
const AsyncErrorPageComponent = Loadable({
  loader: () => import("../routes/error"),
  loading: () => <ContentLoader />,
});

// Terms and Condition
const AsyncTermAndConditionComponent = Loadable({
  loader: () => import("../routes/term-&-conditions"),
  loading: () => <ContentLoader />,
});

// invoice
const AsyncInvoiceComponent = Loadable({
  loader: () => import("../routes/check-out/final-receipt"),
  loading: () => <ContentLoader />,
});

// web
const ProductCategoryComponent = Loadable({
  loader: () => import("../routes/products/productList"),
  loading: () => <ContentLoader />,
});

const ProductsResultsComponent = Loadable({
  loader: () => import("../routes/products/productResult"),
  loading: () => <ContentLoader />,
});

// SignIn
const AsyncSignInPageComponent = Loadable({
  loader: () => import("../routes/signIn"),
  loading: () => <ContentLoader />,
});

// Register
const AsyncSignUpComponent = Loadable({
  loader: () => import("../routes/session/sign-up"),
  loading: () => <ContentLoader />,
});

// ForgetPassword
const AsyncForgetPasswordComponent = Loadable({
  loader: () => import("../routes/session/forget-password"),
  loading: () => <ContentLoader />,
});

const AsyncPrivacyPolicyComponent = Loadable({
  loader: () => import("../routes/privacy-policy"),
  loading: () => <ContentLoader />,
});

// ResetPassword
const AsyncResetPasswordComponent = Loadable({
  loader: () => import("../routes/session/reset-password"),
  loading: () => <ContentLoader />,
});

// Vendors
const AsyncVendorsDetailsComponent = Loadable({
  loader: () => import("../routes/session/vendors"),
  loading: () => <ContentLoader />,
});

// user profile
const AsyncUserProfileComponent = Loadable({
  loader: () => import("../routes/account"),
  loading: () => <ContentLoader />,
});

// Update Password
const AsyncUpdatePasswordComponent = Loadable({
  loader: () => import("../routes/updatePassword"),
  loading: () => <ContentLoader />,
});

// Thank You
const AsyncThankYouComponent = Loadable({
  loader: () => import("../routes/session/thank-you"),
  loading: () => <ContentLoader />,
});

//admin invoice list
const AsyncInvoiceListComponent = Loadable({
  loader: () => import("../routes/admin-panel/invoices"),
  loading: () => <ContentLoader />,
});
//admin Reports list
const AsyncReportsComponent = Loadable({
  loader: () => import("../routes/admin-panel/reports"),
  loading: () => <ContentLoader />,
});
//admin store
const AsyncMerchantStoreComponent = Loadable({
  loader: () => import("../routes/admin-panel/merchantStore"),
  loading: () => <ContentLoader />,
});
//admin profile
const AsyncProfileComponent = Loadable({
  loader: () => import("../routes/admin-panel/profile"),
  loading: () => <ContentLoader />,
});
//admin Catalogue
const AsyncCatalogueComponent = Loadable({
  loader: () => import("../routes/admin-panel/catalogue"),
  loading: () => <ContentLoader />,
});
// admin products grid/list
const AsyncProductsGridComponent = Loadable({
  loader: () => import("../routes/admin-panel/products"),
  loading: () => <ContentLoader />,
});
//add product in admin panel
const AsyncProductAddComponent = Loadable({
  loader: () => import("../routes/admin-panel/products/product-add"),
  loading: () => <ContentLoader />,
});
//edit product in admin panel
const AsyncProductEditComponent = Loadable({
  loader: () => import("../routes/admin-panel/products/product-edit"),
  loading: () => <ContentLoader />,
});

//admin account details
const AsyncProfileDetailComponent = Loadable({
  loader: () => import("../routes/admin-panel/account"),
  loading: () => <ContentLoader />,
});
//home
const AsyncHomeComponent = Loadable({
  loader: () => import("../routes/admin-panel/home"),
  loading: () => <ContentLoader />,
});
//stores
const AsyncStoreListComponent = Loadable({
  loader: () => import("../routes/admin-panel/merchantStore/stores"),
  loading: () => <ContentLoader />,
});
//storeBranding
const AsyncStoreBrandingComponent = Loadable({
  loader: () => import("../routes/admin-panel/merchantStore/storeBranding"),
  loading: () => <ContentLoader />,
});
//userList
const AsyncUserListComponent = Loadable({
  loader: () => import("../routes/admin-panel/profile/userList"),
  loading: () => <ContentLoader />,
});
//groups
const AsyncGroupsComponent = Loadable({
  loader: () => import("../routes/admin-panel/profile/groups"),
  loading: () => <ContentLoader />,
});
//createGroups
const AsyncCreatGroupComponent = Loadable({
  loader: () => import("../routes/admin-panel/profile/createGroups"),
  loading: () => <ContentLoader />,
});

//products
const AsyncproductsComponent = Loadable({
  loader: () => import("../routes/admin-panel/catalogue/products"),
  loading: () => <ContentLoader />,
});
//categories
const AsynccategoriesComponent = Loadable({
  loader: () => import("../routes/admin-panel/catalogue/categories"),
  loading: () => <ContentLoader />,
});
//hierachy
const AsynchierachyComponent = Loadable({
  loader: () => import("../routes/admin-panel/catalogue/hierachy"),
  loading: () => <ContentLoader />,
});
//options
const AsyncoptionsComponent = Loadable({
  loader: () => import("../routes/admin-panel/catalogue/options"),
  loading: () => <ContentLoader />,
});

//optionValues
const AsyncoptionValuesComponent = Loadable({
  loader: () => import("../routes/admin-panel/catalogue/optionValues"),
  loading: () => <ContentLoader />,
});
//featuredProductList
const AsyncfeaturedProductListComponent = Loadable({
  loader: () => import("../routes/admin-panel/catalogue/featuredProductList"),
  loading: () => <ContentLoader />,
});
//manufaturersList
const AsyncmanufaturersListComponent = Loadable({
  loader: () => import("../routes/admin-panel/catalogue/manufacturersList"),
  loading: () => <ContentLoader />,
});

//contentFiles
const AsyncContentFilesComponent = Loadable({
  loader: () => import("../routes/admin-panel/content/contentFiles"),
  loading: () => <ContentLoader />,
});
//contentImages
const AsyncContentImagesComponent = Loadable({
  loader: () => import("../routes/admin-panel/content/contentImages"),
  loading: () => <ContentLoader />,
});
//contentManageBoxes
const AsyncContentManageBoxesComponent = Loadable({
  loader: () => import("../routes/admin-panel/content/contentManageBoxes"),
  loading: () => <ContentLoader />,
});
//contentPageCreate
const AsyncContentPageCreateComponent = Loadable({
  loader: () => import("../routes/admin-panel/content/contentPageCreate"),
  loading: () => <ContentLoader />,
});
//contentPageList
const AsyncContentPageListComponent = Loadable({
  loader: () => import("../routes/admin-panel/content/contentPageList"),
  loading: () => <ContentLoader />,
});
//createbox
const AsyncCreateBoxComponent = Loadable({
  loader: () => import("../routes/admin-panel/content/createBox"),
  loading: () => <ContentLoader />,
});

//merchant Data
const AsyncCategoryMappingComponent = Loadable({
  loader: () => import("../routes/admin-panel/categoryMapping/parent"),
  loading: () => <ContentLoader />,
});
const AsyncChildCategoryMappingComponent = Loadable({
  loader: () => import("../routes/admin-panel/categoryMapping/childs"),
  loading: () => <ContentLoader />,
});
const AsyncProductCategoryMappingComponent = Loadable({
  loader: () => import("../routes/admin-panel/categoryMapping/products"),
  loading: () => <ContentLoader />,
});
const AsyncMappedProductsComponent = Loadable({
  loader: () => import("../routes/admin-panel/categoryMapping/mapped-products"),
  loading: () => <ContentLoader />,
});

const AsyncManufacturerMappingComponent = Loadable({
  loader: () => import("../routes/admin-panel/manufacturerMapping"),
  loading: () => <ContentLoader />,
});

const AsyncProductMappingSingleComponent = Loadable({
  loader: () => import("../routes/admin-panel/productmapping-single/edit"),
  loading: () => <ContentLoader />,
});

// image LeftSidebarNavTab
const AsyncImageNavTab = Loadable({
  loader: () => import("../routes/admin-panel/productmapping-single/image"),
  loading: () => <ContentLoader />,
});
// image LeftSidebarNavTab

//announcements add
const AsyncAnnouncementsAddComponent = Loadable({
  loader: () => import("../routes/admin-panel/announcements/add"),
  loading: () => <ContentLoader />,
});
//announcements list
const AsyncAnnouncementsListComponent = Loadable({
  loader: () => import("../routes/admin-panel/announcements/list"),
  loading: () => <ContentLoader />,
});

//white label list
const AsyncWhiteLabelListComponent = Loadable({
  loader: () => import("../routes/admin-panel/whitelabel"),
  loading: () => <ContentLoader />,
});
//white label list
const AsyncWhiteLabelDetailsComponent = Loadable({
  loader: () => import("../routes/admin-panel/whitelabel/details"),
  loading: () => <ContentLoader />,
});

//Edit Company Details
const AsyncEditWhiteLabelDetailsComponent = Loadable({
  loader: () => import("../routes/admin-panel/whitelabel/edit"),
  loading: () => <ContentLoader />,
});

//Company list
const AsyncCompanyListComponent = Loadable({
  loader: () => import("../routes/admin-panel/company"),
  loading: () => <ContentLoader />,
});

const AsyncCustomerListComponent = Loadable({
  loader: () => import("../routes/admin-panel/customer"),
  loading: () => <ContentLoader />,
});
const AsyncCustomerDetailsComponent = Loadable({
  loader: () => import("../routes/admin-panel/customer/details"),
  loading: () => <ContentLoader />,
});
// Company Details
const AsyncCompanyDetailsComponent = Loadable({
  loader: () => import("../routes/admin-panel/company/details"),
  loading: () => <ContentLoader />,
});

//Edit Company Details
const AsyncEditCompanyDetailsComponent = Loadable({
  loader: () => import("../routes/admin-panel/company/edit"),
  loading: () => <ContentLoader />,
});

//announcements list
const AsyncUsersComponent = Loadable({
  loader: () => import("../routes/admin-panel/users"),
  loading: () => <ContentLoader />,
});

// Company Details
const AsyncUserDetailsComponent = Loadable({
  loader: () => import("../routes/admin-panel/users/details"),
  loading: () => <ContentLoader />,
});

//Edit Company Details
const AsyncEditUserDetailsComponent = Loadable({
  loader: () => import("../routes/admin-panel/users/edit"),
  loading: () => <ContentLoader />,
});

//merchant Data
const AsyncProductMappingComponent = Loadable({
  loader: () => import("../routes/admin-panel/productMapping"),
  loading: () => <ContentLoader />,
});

//product keyword Data
const AsyncProductKeywordComponent = Loadable({
  loader: () =>
    import("../routes/admin-panel/ProductComponent/ProductKeywordComponent"),
  loading: () => <ContentLoader />,
});

//product keyword Data
const AsyncProductOrderComponent = Loadable({
  loader: () => import("../routes/admin-panel/order/"),
  loading: () => <ContentLoader />,
});

//product keyword Data
const AsyncPlacedOrdersComponent = Loadable({
  loader: () => import("../routes/admin-panel/placed-orders"),
  loading: () => <ContentLoader />,
});

const AsyncPlacedOrdersGreenShippedComponent = Loadable({
  loader: () => import("../routes/admin-panel/placed-orders/green_shipping"),
  loading: () => <ContentLoader />,
});

//Order Status
const AsyncOrderStatusComponent = Loadable({
  loader: () => import("../routes/admin-panel/order-status"),
  loading: () => <ContentLoader />,
});

//Home Page
const HomePageComponent = Loadable({
  loader: () => import("../routes/homePage/homePagev2"),
  loading: () => <ContentLoader />,
});

//All Products
const AllProuctsData = Loadable({
  loader: () => import("../routes/products/productCategory"),
  loading: () => <ContentLoader />,
});

// //My Products
// const AllProuctsData = Loadable({
//    loader: () => import('../routes/products/AllProducts'),
//    loading: () => <ContentLoader />,
// });

// Product Detail
const ProductDetailComponent = Loadable({
  loader: () => import("../components/templates/post-detail"),
  loading: () => <ContentLoader />,
});

// Supplier Component
const SupplierComponent = Loadable({
  loader: () => import("../routes/SupplierComponent"),
  loading: () => <ContentLoader />,
});

// Update Supplier Details
const UpdateSupplierComponent = Loadable({
  loader: () => import("../routes/SupplierComponent/updateSupplierDetails"),
  loading: () => <ContentLoader />,
});

// Available Suppliers
const AvailableSupplierComponent = Loadable({
  loader: () => import("../routes/account/SupplierDataComponent"),
  loading: () => <ContentLoader />,
});

//Dashboard banner one Settings
const AsyncAdvertisementsComponent = Loadable({
  loader: () => import("../routes/admin-panel/advertisements"),
  loading: () => <ContentLoader />,
});

//Dashboard banner two Settings
const AsyncDashboardBannerTwoComponent = Loadable({
  // loader: () => import('../routes/admin-panel/dashboardSettings/bannerTwo'),
  loading: () => <ContentLoader />,
});

//Dashboard banner three Settings
const AsyncDashboardBannerThreeComponent = Loadable({
  // loader: () => import('../routes/admin-panel/dashboardSettings/bannerThree'),
  loading: () => <ContentLoader />,
});

// Profile Settings
const AsyncProfileSettingsComponent = Loadable({
  loader: () => import("../routes/admin-panel/profile"),
  loading: () => <ContentLoader />,
});

// Ratings And Reviews
const AsyncRatingsAndReviewsComponent = Loadable({
  loader: () => import("../routes/admin-panel/ratings-reviews"),
  loading: () => <ContentLoader />,
});

// Stores
const AsyncStoresComponent = Loadable({
  loader: () => import("../routes/admin-panel/store"),
  loading: () => <ContentLoader />,
});

// Stores
const AsyncStoreDetailsComponent = Loadable({
  loader: () => import("../routes/admin-panel/store/store-details"),
  loading: () => <ContentLoader />,
});

// Manufacturer Analytics
const AsyncManufacturerAnalytics = Loadable({
  loader: () => import("../routes/analytics/manufacturer"),
  loading: () => <ContentLoader />,
});

// Merchant Analytics
const AsyncMerchantAnalytics = Loadable({
  loader: () => import("../routes/analytics/merchant"),
  loading: () => <ContentLoader />,
});
// Purchase Analytics
const AsyncPurchaseAnalytics = Loadable({
  loader: () => import("../routes/analytics/purchase"),
  loading: () => <ContentLoader />,
});

// Category Analytics
const AsyncCategoryAnalytics = Loadable({
  loader: () => import("../routes/analytics/category"),
  loading: () => <ContentLoader />,
});

// Product Analytics
const AsyncProductAnalytics = Loadable({
  loader: () => import("../routes/analytics/product"),
  loading: () => <ContentLoader />,
});

// Product Analytics
const AsyncGroupAdmin = Loadable({
  loader: () => import("../routes/group-admin"),
  loading: () => <ContentLoader />,
});

// Budget
const BudgetComponent = Loadable({
  loader: () => import("../routes/budget"),
  loading: () => <ContentLoader />,
});

const AddBudgetComponent = Loadable({
  loader: () => import("../routes/budget/add"),
  loading: () => <ContentLoader />,
});

//===================CORPORATE==============
const AsyncCorporateComponent = Loadable({
  loader: () => import("../routes/corporateDashboard"),
  loading: () => <ContentLoader />,
});

//===================Practice Consultant==============
const AsyncPracticeComponent = Loadable({
  loader: () => import("../routes/practice"),
  loading: () => <ContentLoader />,
});

const AsyncAreaManagerComponent = Loadable({
  loader: () => import("../routes/admin-panel/group/area-manager"),
  loading: () => <ContentLoader />,
});

const AsyncPracticeDetailsComponent = Loadable({
  loader: () => import("../routes/practice/psivet/details"),
  loading: () => <ContentLoader />,
});

const AsyncPracticeManufactureCategoryDetailsComponent = Loadable({
  loader: () =>
    import("../routes/practice/psivet/details/manufacture_category_report"),
  loading: () => <ContentLoader />,
});

const AsyncPracticeManufactureProductDetailsComponent = Loadable({
  loader: () =>
    import("../routes/practice/psivet/details/manufacture_product_report"),
  loading: () => <ContentLoader />,
});

// AreaManager Details
const AsyncAreaManagerDetailsComponent = Loadable({
  loader: () => import("../routes/admin-panel/group/area-manager/details"),
  loading: () => <ContentLoader />,
});

// Group Membership
const AsyncGroupMembershipComponent = Loadable({
  loader: () => import("../routes/admin-panel/group/membership"),
  loading: () => <ContentLoader />,
});

// Product Mapping Single
const AsyncCreateProductMappingComponent = Loadable({
  loader: () => import("../routes/admin-panel/productmapping-single/create"),
  loading: () => <ContentLoader />,
});

// Product Price Update
const AsyncProductPriceUpdateComponent = Loadable({
  loader: () => import("../routes/admin-panel/products/price"),
  loading: () => <ContentLoader />,
});

// Product Mapping Single
const AsyncCustomProductComponent = Loadable({
  loader: () =>
    import("../routes/admin-panel/productmapping-single/customProducts"),
  loading: () => <ContentLoader />,
});

// Product Mapping Single
const AsyncEditProductMappingComponent = Loadable({
  loader: () => import("../routes/admin-panel/productmapping-single/edit"),
  loading: () => <ContentLoader />,
});

// Product Mapping Single
const AsyncMapProductMappingComponent = Loadable({
  loader: () => import("../routes/admin-panel/productmapping-single/map"),
  loading: () => <ContentLoader />,
});

// Product Mapping Single
const AsyncUnMapProductMappingComponent = Loadable({
  loader: () => import("../routes/admin-panel/productmapping-single/un-map"),
  loading: () => <ContentLoader />,
});

// Product Mapping Single
const AsyncViewProductMappingComponent = Loadable({
  loader: () => import("../routes/admin-panel/productmapping-single/view"),
  loading: () => <ContentLoader />,
});
// Savings
const AsyncAlternateProductsComponent = Loadable({
  loader: () => import("../routes/admin-panel/productmapping-single/alternate"),
  loading: () => <ContentLoader />,
});
// Savings
const AsyncAddSavingsAlternateProductsComponent = Loadable({
  loader: () =>
    import("../routes/admin-panel/productmapping-single/alternate/addSavings"),
  loading: () => <ContentLoader />,
});
// Savings
const AsyncAddBeforeYouBuyAlternateProductsComponent = Loadable({
  loader: () =>
    import(
      "../routes/admin-panel/productmapping-single/alternate/addBeforeYouBuy"
    ),
  loading: () => <ContentLoader />,
});

// Product Mapping Single
const AsyncConfigProductMappingComponent = Loadable({
  loader: () => import("../routes/admin-panel/products/config"),
  loading: () => <ContentLoader />,
});

// Product Mapping Single
const AsyncEditManufacturerMappingComponent = Loadable({
  loader: () => import("../routes/admin-panel/manufacturermapping-single/edit"),
  loading: () => <ContentLoader />,
});

// Product Mapping Single
const AsyncMapManufacturerMappingComponent = Loadable({
  loader: () => import("../routes/admin-panel/manufacturermapping-single/map"),
  loading: () => <ContentLoader />,
});

// Product Mapping Single
const AsyncUnMapManufacturerMappingComponent = Loadable({
  loader: () =>
    import("../routes/admin-panel/manufacturermapping-single/un-map"),
  loading: () => <ContentLoader />,
});

//Edit AreaManager Details
// const AsyncEditAreaManagerComponent = Loadable({
//    loader: () => import('../routes/admin-panel/group/area-manager/edit'),
//    loading: () => <ContentLoader />,
// });

// Saving Details
const AsyncSavingDetailsComponent = Loadable({
  loader: () => import("../routes/savings"),
  loading: () => <ContentLoader />,
});
const AsyncRebateSavingDetailsComponent = Loadable({
  loader: () => import("../routes/rebate-saving"),
  loading: () => <ContentLoader />,
});
const AsyncPSIVetSavingDetailsComponent = Loadable({
  loader: () => import("../routes/psivet-saving"),
  loading: () => <ContentLoader />,
});

// Vendors
const AsyncSessionGroupComponent = Loadable({
  loader: () => import("../routes/session/group"),
  loading: () => <ContentLoader />,
});

const AsyncPracticeSignUpsComponent = Loadable({
  loader: () => import("../routes/signUps"),
  loading: () => <ContentLoader />,
});

const AsyncGroupReportsComponent = Loadable({
  loader: () => import("../routes/reports"),
  loading: () => <ContentLoader />,
});

//product keyword Data
const AsyncOrdersHistoryPracticeComponent = Loadable({
  loader: () => import("../routes/admin-panel/order-history-practice"),
  loading: () => <ContentLoader />,
});

const PsivetPoints = Loadable({
  loader: () => import("../routes/points"),
  loading: () => <ContentLoader />,
});

//product keyword Data
const AsyncOrderConfigurationComponent = Loadable({
  loader: () => import("../routes/admin-panel/order-configuration"),
  loading: () => <ContentLoader />,
});

// contact
const AsyncPartnersComponent = Loadable({
  loader: () => import("../routes/partners"),
  loading: () => <ContentLoader />,
});

const AsyncTestComponent = Loadable({
  loader: () => import("../routes/test/"),
  loading: () => <ContentLoader />,
});

//Jobs ui list
const AsyncJobListComponent = Loadable({
  loader: () => import("../routes/admin-panel/job"),
  loading: () => <ContentLoader />,
});

//Jobs admin list
const AsyncJobAdminListComponent = Loadable({
  loader: () => import("../routes/admin-panel/job/job_list"),
  loading: () => <ContentLoader />,
});

//JobOrderHistory list
const AsyncJobOrderHistoryListComponent = Loadable({
  loader: () => import("../routes/admin-panel/job/orderHistory"),
  loading: () => <ContentLoader />,
});

//JobOrderHistory Company list
const AsyncJobOrderHistoryCompanyListComponent = Loadable({
  loader: () => import("../routes/admin-panel/job/orderHistory/detail"),
  loading: () => <ContentLoader />,
});

const AsyncOrderDiscrepancyListComponent = Loadable({
  loader: () => import("../routes/admin-panel/order/discrepancy"),
  loading: () => <ContentLoader />,
});

const AsyncProgramComponent = Loadable({
  loader: () => import("../routes/program"),
  loading: () => <ContentLoader />,
});

//group and group options
const AsyncGroupComponent = Loadable({
  loader: () => import("../routes/admin-panel/group"),
  loading: () => <ContentLoader />,
});

const AsyncGroupOptionsComponent = Loadable({
  loader: () => import("../routes/admin-panel/group/group-options"),
  loading: () => <ContentLoader />,
});

const AsyncGroupAreaComponent = Loadable({
  loader: () => import("../routes/admin-panel/group/group-area"),
  loading: () => <ContentLoader />,
});

const AsyncPointsComponent = Loadable({
  loader: () => import("../routes/admin-panel/points"),
  loading: () => <ContentLoader />,
});

const AsyncPointsDetailComponent = Loadable({
  loader: () => import("../routes/admin-panel/points/point_detail"),
  loading: () => <ContentLoader />,
});

const AsyncCovetrusProgramComponent = Loadable({
  loader: () => import("../routes/program/covetrus"),
  loading: () => <ContentLoader />,
});

const AsyncHistorySummaryComponent = Loadable({
  loader: () => import("../routes/admin-panel/historysummary"),
  loading: () => <ContentLoader />,
});
const AsyncAdminVendorComponent = Loadable({
  loader: () => import("../routes/admin-panel/vendors"),
  loading: () => <ContentLoader />,
});

const AsyncProgramListComponent = Loadable({
  loader: () => import("../routes/admin-panel/program/parent"),
  loading: () => <ContentLoader />,
});

const AsyncProgramCompanyComponent = Loadable({
  loader: () => import("../routes/admin-panel/program/add-company"),
  loading: () => <ContentLoader />,
});

const AsyncProgramCompanyListComponent = Loadable({
  loader: () => import("../routes/admin-panel/program/list-company"),
  loading: () => <ContentLoader />,
});
const AsyncProgramRewardComponent = Loadable({
  loader: () => import("../routes/admin-panel/program/reward"),
  loading: () => <ContentLoader />,
});

const AsyncSystemListComponent = Loadable({
  loader: () => import("../routes/admin-panel/system-configuration"),
  loading: () => <ContentLoader />,
});

const AsyncAdminFaqComponent = Loadable({
  loader: () => import("../routes/admin-panel/faq"),
  loading: () => <ContentLoader />,
});

const AsyncAdminEditFAQComponent = Loadable({
  loader: () => import("../routes/admin-panel/faq/edit"),
  loading: () => <ContentLoader />,
});

const AsyncGroupWhiteLabelComponent = Loadable({
  loader: () => import("../routes/admin-panel/group/whitelabel"),
  loading: () => <ContentLoader />,
});
const AsyncPracticeListEditComponent = Loadable({
  loader: () => import("../routes/admin-panel/practice-edit/list"),
  loading: () => <ContentLoader />,
});

const AsyncPracticeDetailEditComponent = Loadable({
  loader: () => import("../routes/admin-panel/practice-edit/detail"),
  loading: () => <ContentLoader />,
});

const AsyncReleaseNotesComponent = Loadable({
  loader: () => import("../routes/admin-panel/release-notes"),
  loading: () => <ContentLoader />,
});
const AsyncEditReleaseNotesComponent = Loadable({
  loader: () => import("../routes/admin-panel/release-notes/EditReleaseNotes"),
  loading: () => <ContentLoader />,
});

const AsyncViewReleaseNotesComponent = Loadable({
  loader: () => import("../routes/admin-panel/release-notes/ViewReleaseNotes"),
  loading: () => <ContentLoader />,
});

export const AsyncNewHomepage = Loadable({
  loader: () => import("redesign/pages/Homepage"),
  loading: () => <ContentLoader />,
});

export {
  AsyncCategoryMappingComponent,
  AsyncCreateBoxComponent,
  AsyncContentPageListComponent,
  AsyncContentPageCreateComponent,
  AsyncContentManageBoxesComponent,
  AsyncContentImagesComponent,
  AsyncContentFilesComponent,
  AsynccategoriesComponent,
  AsynchierachyComponent,
  AsyncoptionsComponent,
  AsyncoptionValuesComponent,
  AsyncfeaturedProductListComponent,
  AsyncmanufaturersListComponent,
  AsyncproductsComponent,
  AsyncMyProductsComponent,
  AsyncStoreListComponent,
  AsyncStoreBrandingComponent,
  AsyncCartComponent,
  AsyncCheckOutComponent,
  AsyncBlogDetailComponent,
  AsyncAccessoriesComponent,
  AsyncContactUsComponent,
  AsyncFAQComponent,
  AsyncManageOrder,
  AsyncTermAndConditionComponent,
  AsyncInvoiceComponent,
  ProductCategoryComponent,
  AsyncSignInPageComponent,
  AsyncSignUpComponent,
  AsyncForgetPasswordComponent,
  AsyncUpdatePasswordComponent,
  AsyncUserProfileComponent,
  AsyncThankYouComponent,
  AsyncReportsComponent,
  AsyncProductsGridComponent,
  AsyncProductAddComponent,
  AsyncProductEditComponent,
  AsyncProfileDetailComponent,
  AsyncPageNotFoundComponent,
  AsyncMerchantStoreComponent,
  AsyncProfileComponent,
  AsyncCatalogueComponent,
  AsyncUserListComponent,
  AsyncGroupsComponent,
  AsyncCreatGroupComponent,
  AsyncHomeComponent,
  AsyncViewProductsComponent,
  AsyncCustomerListComponent,
  AsyncCustomerDetailsComponent,
  UserLogin,
  ThirdPartyLogin,
  AllProuctsData,
  ProductDetailComponent,
  HomePageComponent,
  AsyncProductMappingComponent,
  ProductsResultsComponent,
  AsyncProductKeywordComponent,
  SupplierComponent,
  UpdateSupplierComponent,
  AvailableSupplierComponent,
  AsyncAdvertisementsComponent,
  AsyncDashboardBannerTwoComponent,
  AsyncDashboardBannerThreeComponent,
  AsyncProfileSettingsComponent,
  AsyncOrderComponent,
  AsyncInvoicesComponent,
  AsyncProductOrderComponent,
  AsyncRatingsAndReviewsComponent,
  AsyncStoresComponent,
  AsyncStoreDetailsComponent,
  AsyncOrderProductComponent,
  AsyncOrderManufactureComponent,
  AsyncVendorsDetailsComponent,
  AsyncAnnouncementsAddComponent,
  AsyncAnnouncementsListComponent,
  AsyncNotificationsComponent,
  AsyncMyFavouritesComponent,
  AsyncMyShoppingListsComponent,
  AsyncResetPasswordComponent,
  AsyncPromotionComponent,
  AsyncRebateComponent,
  AsyncManufacturerAnalytics,
  AsyncMerchantAnalytics,
  AsyncPurchaseAnalytics,
  AsyncCategoryAnalytics,
  AsyncProductAnalytics,
  AsyncGroupAdmin,
  AsyncCompanyListComponent,
  AsyncCompanyDetailsComponent,
  AsyncEditCompanyDetailsComponent,
  AsyncErrorPageComponent,
  PsivetTransaction,
  BudgetComponent,
  AddBudgetComponent,
  AsyncBackorderedComponent,
  AsyncPSIPromotionComponent,
  AsyncCorporateComponent,
  AsyncManufacturerMappingComponent,
  AsyncPracticeComponent,
  AsyncAreaManagerComponent,
  AsyncAreaManagerDetailsComponent,
  AsyncGroupMembershipComponent,
  AsyncProductMappingSingleComponent,
  AsyncCreateProductMappingComponent,
  AsyncProductPriceUpdateComponent,
  AsyncEditProductMappingComponent,
  AsyncMapProductMappingComponent,
  AsyncUnMapProductMappingComponent,
  AsyncViewProductMappingComponent,
  AsyncRebateSavingDetailsComponent,
  AsyncPrivacyPolicyComponent,
  AsyncEditManufacturerMappingComponent,
  AsyncMapManufacturerMappingComponent,
  AsyncUnMapManufacturerMappingComponent,
  AsyncPSIVetSavingDetailsComponent,
  AsyncSavingDetailsComponent,
  AsyncSessionGroupComponent,
  AsyncPlacedOrdersComponent,
  AsyncOrdersHistoryPracticeComponent,
  AsyncPracticeSignUpsComponent,
  PsivetPoints,
  AsyncConfigProductMappingComponent,
  AsyncOrderConfigurationComponent,
  AsyncPartnersComponent,
  AsyncTestComponent,
  AsyncJobListComponent,
  AsyncJobOrderHistoryListComponent,
  AsyncJobOrderHistoryCompanyListComponent,
  AsyncOrderDiscrepancyListComponent,
  AsyncPracticeDetailsComponent,
  AsyncProgramComponent,
  AsyncGroupReportsComponent,
  AsyncUsersComponent,
  AsyncUserDetailsComponent,
  AsyncEditUserDetailsComponent,
  AsyncGroupComponent,
  AsyncGroupOptionsComponent,
  AsyncGroupAreaComponent,
  AsyncPointsComponent,
  AsyncPointsDetailComponent,
  AsyncWhiteLabelListComponent,
  AsyncWhiteLabelDetailsComponent,
  AsyncEditWhiteLabelDetailsComponent,
  AsyncJobAdminListComponent,
  AsyncPointBasedProductPromotionComponent,
  AsyncCovetrusProgramComponent,
  AsyncVetscoutPromotionComponent,
  AsyncHistorySummaryComponent,
  AsyncAdminVendorComponent,
  AsyncChildCategoryMappingComponent,
  AsyncPracticeManufactureCategoryDetailsComponent,
  AsyncProductCategoryMappingComponent,
  AsyncOrderStatusComponent,
  AsyncMappedProductsComponent,
  AsyncPracticeManufactureProductDetailsComponent,
  AsyncCustomProductComponent,
  AsyncAlternateProductsComponent,
  AsyncImageNavTab,
  AsyncAddSavingsAlternateProductsComponent,
  AsyncAddBeforeYouBuyAlternateProductsComponent,
  AsyncProgramListComponent,
  AsyncProgramCompanyComponent,
  AsyncProgramRewardComponent,
  AsyncProgramCompanyListComponent,
  AsyncSystemListComponent,
  AsyncAdminFaqComponent,
  AsyncAdminEditFAQComponent,
  AsyncGroupWhiteLabelComponent,
  AsyncPracticeListEditComponent,
  AsyncPracticeDetailEditComponent,
  AsyncPlacedOrdersGreenShippedComponent,
  AsyncReleaseNotesComponent,
  AsyncEditReleaseNotesComponent,
  AsyncViewReleaseNotesComponent,
};
