/**
 * site header one component
 */
/* eslint-disable */
import React, { Fragment } from "react";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import HeaderMenu from "./HeaderMenu";
import SearchBox from "./SearchBox";
import SidebarMenu from "../sidebar";
import FixedHeader from "./FixedHeader";
import AppConfig from "../../../constants/AppConfig";
import HeaderDiv from "./HeaderDiv";
import Hyperwallet from "./Hyperwallet";
import { local } from "constant";
import { getAccessToken, getAdditionalHeaders } from "../../../../Authorization";
import "./HeaderOne.css";
import CompanySearchBox from "./CompanySearchBox";
import {
  getProfile,
  logError,
  getBucks,
  getNotifications,
  showVendorList,
  getCompanyWishLists,
  setRole,
} from "../../../actions/action";
import { connect } from "react-redux";
import TopFixedMessage from "redesign/components/TopFixedMessage";

class HeaderOne extends React.Component {
  unregisterHistoryListener = null;

  constructor(props) {
    super(props);
    this.confirmationDialog = React.createRef();
    this.state = {
      isThirdParty: false,
      fixedHeader: false,
      balance: "$0.00",
      showBucks: false,
      showPoints: false,
      links: [],
      points: 0,
      groupModel: null,
      searchStr: "",
    };
  }

  componentDidMount() {
    if (window.location.pathname.includes("CreateCallback")) {
      this.setState({
        isThirdParty: true,
      });
    }
    this.unregisterHistoryListener = this.props.history.listen((loc, act) => {
      if (loc.pathname?.indexOf("/results") == -1) {
        this.setState({ searchStr: "" });
      }
    });

    // this.getMenuData();
    // this.props.getBucks();
    // this.getBalance();
    // this.props.getProfile();
    // this.props.getNotifications();
    // this.props.showVendorList();
  }

  UNSAFE_componentWillMount() {
    window.addEventListener("scroll", this.hideBar);
    // this.props.getBucks();
    // this.getBalance();
    // this.props.getNotifications();
    // this.props.showVendorList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.links.length === 0) {
      this.getMenuData();
      this.props.getCompanyWishLists();
    }
    if (!this.state.balance) {
      this.getBalance();
    }
    if (this.state.points === 0) {
      this.getPoints();
    }
    if (this.props.bucks && prevProps.bucks !== this.props.bucks) {
      this.setState({
        showBucks: this.props.bucks.showBucks,
        groupModel: this.props.bucks,
      });
      if (
        this.props.bucks.hyperwalletBalance &&
        this.props.bucks.hyperwalletBalance.amount
      ) {
        this.setState({ balance: this.props.bucks.hyperwalletBalance.amount });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.hideBar);
    if (this.unregisterHistoryListener) {
      this.unregisterHistoryListener();
    }
  }

  getPoints = () => {
    var sessionData = getAccessToken();
    if (sessionData) {
      fetch(local.baseUrl + `api/v3/points`, {
        method: "get",
        headers: new Headers({
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "bearer " + sessionData,
          ...getAdditionalHeaders(),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            return;
          }
        })
        .then((data) => {
          if (data) {
            this.setState({ points: data, showPoints: data.showPoints });
          }
        });
    }
  };

  getBalance = () => {
    var sessionData = getAccessToken();
    if (sessionData) {
      fetch(local.baseUrl + `api/v3/group/balance`, {
        method: "get",
        headers: new Headers({
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "bearer " + sessionData,
          ...getAdditionalHeaders(),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            this.props.logError(response);
            return;
          }
        })
        .then((data) => {
          if (data) {
            this.setState({ showBucks: data.showBucks });
            if (data.hyperwalletBalance && data.hyperwalletBalance.amount) {
              this.setState({ balance: data.hyperwalletBalance.amount });
            }
          }
        });
    }
  };

  getMenuData() {
    var sessionData = getAccessToken();
    let url = "";
    let domainUrlParam = "";
    if (
      window.location.pathname &&
      window.location.pathname.includes("admin")
    ) {
      url = "default";
    } else {
      url = window.location.host;
    }
    if (url != null) {
      domainUrlParam = `&domainUrl=${url}`;
    }
    if (sessionData) {
      fetch(local.baseUrl + `api/v3/menu/active?${domainUrlParam}`, {
        method: "get",
        headers: new Headers({
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "bearer " + sessionData,
          ...getAdditionalHeaders(),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            this.props.logError(response);
            return;
          }
        })
        .then((data) => {
          if (data && data.menuDatas) {
            this.setState({ links: data.menuDatas });
          }
        });
    }
  }

  hideBar = () => {
    const { fixedHeader } = this.state;
    this.scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.scrollTop >= 200
      ? !fixedHeader && this.setState({ fixedHeader: true })
      : fixedHeader && this.setState({ fixedHeader: false });
  };

  redirectToHome = () => {
    const redirectUrl = sessionStorage.getItem("redirectUrl");
    this.props.history.push("/homePage");
  };

  onChangeAppSearchStr = (searchStr) => {
    this.setState({ searchStr });
  };

  render() {
    const support = JSON.parse(sessionStorage.getItem("support"));

    const {
      balance,
      showBucks,
      showPoints,
      links,
      points,
      groupModel,
      isThirdParty,
    } = this.state;
    const { appAttributes, role } = this.props;
    const groupAreaManager =
      role === "CUSTOMER"
        ? false
        : JSON.parse(sessionStorage.getItem("groupAreaManager")) ||
          JSON.parse(sessionStorage.getItem("adminAreaManager"));
    // const supportId = sessionStorage.getItem("support");
    return (
      <div
        style={{
          display: window.location.pathname === "/admin" ? "none" : "block",
        }}
      >
        <AppBar
          position="static"
          className={`iron-header-wrapper bg-primary iron-header-v1 ${
            this.state.fixedHeader ? "header-fixed" : ""
          }`}
        >
          <div
            className={`iron-header-top py-5 ${
              isThirdParty && "third-party-header-top"
            }`}
          >
            <div className="container">
              {isThirdParty ? (
                <Grid container spacing={24}>
                  <Grid
                    item
                    xs={9}
                    sm={9}
                    md={2}
                    lg={2}
                    xl={2}
                    className={`d-flex align-items-center third-party-logo-container`}
                  >
                    <div className="iron-header-widgets">
                      <div className="iron-app-logo">
                        <a
                          onClick={() => {
                            this.redirectToHome();
                          }}
                        >
                          <img
                            src={
                              this.props.appAttributes &&
                              this.props.appAttributes.LOGO_URL
                                ? this.props.appAttributes.LOGO_URL
                                : AppConfig.AppLogo
                            }
                            alt="header-logo"
                          />
                        </a>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <>
                  {groupAreaManager != null && groupAreaManager ? (
                    <Grid container spacing={24}>
                      <Grid
                        item
                        xs={9}
                        sm={9}
                        md={2}
                        lg={2}
                        xl={2}
                        className="d-flex align-items-center"
                      >
                        <div className="iron-header-widgets">
                          <div className="iron-app-logo logoImage">
                            <a
                              onClick={() => {
                                this.redirectToHome();
                              }}
                            >
                              <img
                                src={
                                  this.props.appAttributes &&
                                  this.props.appAttributes.LOGO_URL
                                    ? this.props.appAttributes.LOGO_URL
                                    : AppConfig.AppLogo
                                }
                                alt="header-logo"
                              />
                            </a>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={8}
                        className="py-30"
                      >
                        <div className="text-center">
                          <h3 style={{ color: "white" }}>
                            {appAttributes &&
                            appAttributes.DASHBOARD_TITLE_LABEL
                              ? appAttributes.DASHBOARD_TITLE_LABEL
                              : "Dashboard"}
                          </h3>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={2}
                        lg={2}
                        xl={2}
                        className="d-flex justify-content-center align-items-center userOptions py-30"
                      >
                        <div className="iron-header-widgets d-flex justify-content-center align-items-center ">
                          {/* role came from reducer */}
                          {role === "CUSTOMER" ? (
                            <HeaderDiv
                              showBucks={showBucks}
                              groupModel={groupModel}
                              balance={balance}
                              groupAreaManager={groupAreaManager}
                              history={this.props.history}
                              location={this.props.location}
                              points={points}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  ) : support != null && !support ? (
                    <Grid container spacing={24}>
                      <Grid
                        item
                        xs={9}
                        sm={9}
                        md={2}
                        lg={2}
                        xl={2}
                        className="d-flex align-items-center"
                      >
                        <div className="iron-header-widgets">
                          <div className="iron-app-logo logoImage">
                            {role === "CUSTOMER" ? (
                              <a
                                onClick={() => {
                                  this.redirectToHome();
                                }}
                              >
                                <img
                                  src={
                                    this.props.appAttributes &&
                                    this.props.appAttributes.LOGO_URL
                                      ? this.props.appAttributes.LOGO_URL
                                      : AppConfig.AppLogo
                                  }
                                  alt="header-logo"
                                />
                              </a>
                            ) : (
                              <img
                                src={
                                  this.props.appAttributes &&
                                  this.props.appAttributes.LOGO_URL
                                    ? this.props.appAttributes.LOGO_URL
                                    : AppConfig.AppLogo
                                }
                                alt="header-logo"
                              />
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {role === "CUSTOMER" ? (
                          <div className="searchBox">
                            <SearchBox
                              history={this.props.history}
                              location={this.props.location}
                              searchStr={this.state.searchStr}
                              onSearchChange={this.onChangeAppSearchStr}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        xl={2}
                      >
                        {role === "CUSTOMER" ? (
                          <Hyperwallet
                            balance={balance}
                            showBucks={showBucks}
                            groupModel={groupModel}
                            history={this.props.history}
                            location={this.props.location}
                            showPoints={showPoints}
                            points={points}
                          />
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={2}
                        lg={2}
                        xl={2}
                        className="d-flex justify-content-center align-items-center userOptions py-30"
                      >
                        <div className="iron-header-widgets d-flex justify-content-center align-items-center ">
                          {role === "CUSTOMER" ? (
                            <HeaderDiv
                              balance={balance}
                              groupModel={groupModel}
                              showBucks={showBucks}
                              groupAreaManager={groupAreaManager}
                              history={this.props.history}
                              location={this.props.location}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs={9}
                        sm={9}
                        md={3}
                        lg={2}
                        xl={2}
                        className="d-flex align-items-center"
                      >
                        <div className="iron-header-widgets d-flex justify-content-center align-items-center login-logo">
                          <div className="iron-app-logo logoImage">
                            <a
                              onClick={() => {
                                this.redirectToHome();
                              }}
                            >
                              <img
                                src={
                                  this.props.appAttributes &&
                                  this.props.appAttributes.LOGO_URL
                                    ? this.props.appAttributes.LOGO_URL
                                    : AppConfig.AppLogo
                                }
                                alt="header-logo"
                              />
                            </a>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={5}
                        xl={5}
                        className="search-box-container"
                      >
                        {role === "CUSTOMER" ? (
                          <div className="searchBox">
                            <SearchBox
                              history={this.props.history}
                              location={this.props.location}
                              searchStr={this.state.searchStr}
                              onSearchChange={this.onChangeAppSearchStr}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={3}
                        xl={3}
                        className="company-search-box-container"
                      >
                        {role === "CUSTOMER" ? (
                          <div className="searchBox">
                            <Hyperwallet
                              balance={balance}
                              showBucks={showBucks}
                              groupModel={groupModel}
                              history={this.props.history}
                              location={this.props.location}
                              showPoints={showPoints}
                              points={points}
                            />
                            <CompanySearchBox
                              history={this.props.history}
                              location={this.props.location}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={3}
                        lg={2}
                        xl={2}
                        className="d-flex justify-content-center align-items-center userOptions py-30"
                      >
                        <div className="iron-header-widgets d-flex justify-content-center align-items-center ">
                          {role?.trim() === "CUSTOMER" ? (
                            <HeaderDiv
                              balance={balance}
                              groupModel={groupModel}
                              showBucks={showBucks}
                              groupAreaManager={false}
                              history={this.props.history}
                              location={this.props.location}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </div>
          </div>
          <div
            className={`iron-header-bottom ${
              isThirdParty && "third-party-header-bottom"
            }`}
          >
            <div className="container">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="text-center position-relative">
                  {role?.trim() === "CUSTOMER" ? (
                    <HeaderMenu
                      balance={balance}
                      groupModel={groupModel}
                      showBucks={showBucks}
                      history={this.props.history}
                      location={this.props.location}
                      links={links}
                    />
                  ) : (
                    ""
                  )}

                  <SidebarMenu
                    history={this.props.history}
                    location={this.props.location}
                    links={links}
                  />
                </div>
              </Grid>
            </div>
          </div>
          {role === "CUSTOMER" ? (
            <Fragment>
              <FixedHeader
                points={points}
                showPoints={showPoints}
                groupModel={groupModel}
                showBucks={showBucks}
                location={this.props.location}
                history={this.props.history}
                balance={balance}
                groupAreaManager={groupAreaManager}
                links={links}
                searchStr={this.state.searchStr}
                onSearchChange={this.onChangeAppSearchStr}
              />
            </Fragment>
          ) : (
            ""
          )}
        </AppBar>
        <TopFixedMessage />
      </div>
    );
  }
}

// export default HeaderOne;

const mapStateToProps = ({ ecommerce, appSettings }) => {
  const { bucks, role } = ecommerce;
  const { appAttributes } = appSettings;
  return { bucks, appAttributes, role };
};

export default connect(mapStateToProps, {
  getProfile,
  logError,
  getBucks,
  getNotifications,
  showVendorList,
  getCompanyWishLists,
  setRole,
})(HeaderOne);
