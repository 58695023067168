import React from "react";
import Header from "redesign/components/Header";
import "./RedesignLayout.scss";

const RedesignLayout = ({ children }) => {
  return (
    <div className="redesign-layout">
      <Header />
      <main className="redesign-content">{children}</main>
      <footer>Footer</footer>
    </div>
  );
};

export default RedesignLayout;
