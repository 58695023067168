import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import { Router, Switch, Route } from "react-router-dom";
import history from "./history";
import { configureStore } from "legacy/store/index";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
// store
export const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route
          path="/"
          render={(props) => (
            <App {...props} location={props.location} history={history} />
          )}
        />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root"),
);

// registerServiceWorker();
unregisterServiceWorker();
