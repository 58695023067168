import React from "react";
import SectionBanner from "redesign/components/SectionBanner";
import SectionInformation from "redesign/components/SectionInformation";
import { useRtkSelector, useRtkDispatch } from "redesign/redux/hooks/hooks";
import "./index.scss";

const Homepage = () => {
  return (
    <div className="homepage-wrapper">
      <SectionBanner />
      <SectionInformation />
      <SectionBanner />
      <SectionInformation />
      <SectionBanner />
      <SectionInformation />
      <SectionBanner />
    </div>
  );
};

export default Homepage;
