import React from "react";
import "./index.scss";

const SectionBanner = () => {
  return (
    <div className="main-banner-section">
      <div className="rectangle column-large">Large Banner</div>
      <div className="column-small">
        <div className="rectangle">Small Banner 1</div>
        <div className="rectangle">Small Banner 2</div>
      </div>
    </div>
  );
};

export default SectionBanner;
