// TODO: check whether this method will be used or not
/* eslint-disable */
import React, { Component, Fragment } from 'react';
import './cart.css';
// import { Button } from '@material-ui/core';
import { Dialog } from 'primereact/dialog';
import { Button, Grid, Divider, FormControl, CardMedia } from '@material-ui/core';
import 'primereact/resources/primereact.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { showAlert } from "../../../actions/action";
import ContentLoader from '../loaders/ContentLoader';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { local } from "constant";
import CartPricecomponent from '../add-to-cart/productPriceComponent';
import { HtmlTooltip } from '../html-tool-tip';
import { getProductDetailService } from '../../../services';

const settings = {
   dots: true,
   infinite: true,
   arrows: true,
   speed: 500,
   autoplay: false,
   slidesToShow: 1,
   slidesToScroll: 1,
   responsive: [
   ]
}

class Savings extends React.Component {

   constructor(props) {
      super(props);
      this.confirmationDialog = React.createRef();
      this.state = {
         loading: false,
         quantityTextVisible: false,
         productId: null,
         productDetails: null,
         desc: "",
         name: "",
         images: [],
         parentProduct: null,
         savingPrice: 0
      };
   }

   componentDidMount() {
      this.setState({ productId: this.props.data.productId, parentProduct: this.props.data.product }, () => {
         this.getProductDetails(Number(this.state.productId));
      });
   }

   calculateSavings = () => {
      const { productDetails, parentProduct } = this.state;
      if (productDetails?.lowestPrice && parentProduct?.lowestPrice) {
         if (!isNaN(productDetails?.lowestPrice) && !isNaN(parentProduct?.lowestPrice)) {
            let savingPrice = (parentProduct?.lowestPrice - productDetails?.lowestPrice).toFixed(2);
            this.setState({ savingPrice });
         }
      }
   }


   getProductDetails = (id) => {
      getProductDetailService(id)
         .then((res) => {
            const { data } = res
            this.setState(
               {
                  productDetails: data,
                  desc: data.description.description,
                  name: data.description.name,
                  images: data.images && data.images.length ? data.images : []
               }
            )
         }, () => {
            this.calculateSavings();
         })
   };
   updateMerchantArr = (selectedMerchant, merchantChange, firstLoaded, checkboxClicked = false) => {

      if (checkboxClicked) {
         this.setState({ quantity: "1" }, () => { })
      }

      let { quantity } = this.state;
      if (selectedMerchant && selectedMerchant.orderable) {
         var productData = this.state.productDetails;
         var price = 0;
         let merchantId = 0;
         productData.identicalList = [];
         let merchantProductPriceObj = null;
         let merchantProduct = 0;
         if (merchantChange) {
            productData.merchantId = selectedMerchant.merchantId;
            productData.merchantProduct = selectedMerchant.productId;
            productData.productPrice = selectedMerchant.priceBigDecimal;
            productData.firstLoaded = merchantChange;
            productData.selectedMerchant = selectedMerchant.merchantId;
            if (selectedMerchant.productCartConfigModel && selectedMerchant.productCartConfigModel.multiple) {
               this.setState({ multiple: selectedMerchant.productCartConfigModel.multiple });
            }
            if (selectedMerchant.productCartConfigModel && selectedMerchant.productCartConfigModel.minimumQuantity) {
               this.setState({ minimumQuantity: selectedMerchant.productCartConfigModel.minimumQuantity });
            }
            if (!productData.addToCart) {
               if (selectedMerchant.productCartConfigModel && selectedMerchant.productCartConfigModel.minimumQuantity) {
                  quantity = selectedMerchant.productCartConfigModel.minimumQuantity
               } else {
                  quantity = 1;
               }
               this.setState({ quantity }, () => {
                  if (quantity > 1) {
                     this.setState({ total: selectedMerchant.priceBigDecimal * quantity });
                  } else {
                     this.setState({ total: null });
                  }
               })
            }
            this.setState({ productDetails: productData, newPrice: price, selectedCartConfig: selectedMerchant.productCartConfigModel });
         } else {
            if (firstLoaded) {
               this.setState({ productDetails: productData, newPrice: price });
            } else {
               productData.merchantArray.map(item => {
                  if (item.login && item.orderable && item.availableModel && item.availableModel.canOrderOnline) {
                     if (price) {
                        if (merchantId == 0) {
                           merchantId = item.merchantId;
                           merchantProduct = item.productId;
                           merchantProductPriceObj = item;
                        }
                        if (price > item.priceBigDecimal) {
                           price = item.priceBigDecimal;
                           merchantId = item.merchantId;
                           merchantProduct = item.productId;
                           merchantProductPriceObj = item;
                        } else {
                           merchantId = merchantId;
                           merchantProduct = merchantProduct;
                           price = price;
                           merchantProductPriceObj = merchantProductPriceObj;
                        }
                     } else {
                        price = item.priceBigDecimal
                        merchantId = item.merchantId;
                        merchantProduct = item.productId;
                        merchantProductPriceObj = item;
                     }
                     productData.identicalList.push(item);
                     productData.merchantId = merchantId;
                     productData.merchantProduct = merchantProduct;
                     productData.productPrice = price;
                     productData.lowestPrice = price;
                     if (merchantProductPriceObj && merchantProductPriceObj.productCartConfigModel && merchantProductPriceObj.productCartConfigModel.multiple) {
                        this.setState({ multiple: merchantProductPriceObj.productCartConfigModel.multiple });
                     }
                     if (merchantProductPriceObj && merchantProductPriceObj.productCartConfigModel && merchantProductPriceObj.productCartConfigModel.minimumQuantity) {
                        this.setState({ minimumQuantity: merchantProductPriceObj.productCartConfigModel.minimumQuantity });
                     }
                     if (!productData.addToCart) {
                        if (merchantProductPriceObj && merchantProductPriceObj.productCartConfigModel && merchantProductPriceObj.productCartConfigModel.minimumQuantity) {
                           quantity = merchantProductPriceObj.productCartConfigModel.minimumQuantity;
                        } else {
                           quantity = 1;
                        }
                        this.setState({ quantity }, () => {
                           if (quantity > 1) {
                              this.setState({ total: merchantProductPriceObj.priceBigDecimal * quantity });
                           } else {
                              this.setState({ total: null });
                           }
                        })
                     }
                     this.setState({ productDetails: productData, newPrice: price, selectedCartConfig: merchantProductPriceObj.productCartConfigModel }, () => {
                     });
                  }
               })
               if (productData.identicalList) {
                  this.identical(productData.identicalList, price, productData);
               }
            }
         }
      }
   }

   updateOrderOnline = (index, selectedMerchant) => {
      var orderOnline = [];
      var productData = this.state.productDetails;
      var merchantObj = productData.productMerchantPrices[index];
      merchantObj.availableModel = selectedMerchant.availableModel;
      this.setState({ productDetails: productData }, () => {
         var productData = this.state.productDetails;
         productData.productMerchantPrices.map((detail) => {
            if (detail.availableModel && detail.availableModel.canOrderOnline == false) {
               orderOnline.push(false);
            }
         });
         productData.orderOnline = orderOnline;
         this.setState({ productDetails: productData }, () => {
         });
      });
   };

   identical = (array, lowestPrice, productData) => {
      var count = 0;
      var defaultMerchant = null;
      array.map((data) => {
         if (data.priceBigDecimal === lowestPrice) {
            count = count + 1;
         }
         if (productData.defaultMerchantId == data.merchantId) {
            defaultMerchant = data;
         }
      })
      if (count > 1) {
         productData.samePriceFlag = true;
      } else {
         productData.samePriceFlag = false;
      }
      if (count === array.length && defaultMerchant && defaultMerchant.productId) {
         productData.merchantId = defaultMerchant.merchantId;
         productData.merchantProduct = defaultMerchant.productId;
      }
      this.setState({ productDetails: productData, newPrice: 0 });
   }


   render() {
      const { show } = this.props;
      const { productDetails, name, images, quantity, parentProduct, savingPrice } = this.state;
      return (
         <div>
            <div className="card">
               <Dialog className="main-dialogue" header={`You could save at least $ ${(parentProduct?.lowestPrice - productDetails?.lowestPrice)} by purchasing this item instead:`} visible={show} style={{ width: '50vw' }}
                  position="top" onHide={this.props.hidePopup}
               >
                  {/* {vendorObj ? */}
                  <div style={{ marginBottom: '1rem' }}>
                     <div>
                        <Grid container spacing={24} className="my-0">
                           <Grid item xs={12} sm={12} md={2} lg={2} className="py-0 d-flex justify-content-center align-items-center" >
                              <h5>Product</h5>
                           </Grid>
                           <Grid item xs={12} sm={12} md={2} lg={2} className="py-0 d-flex justify-content-center align-items-center" >
                              <h5>Description</h5>
                           </Grid>
                           <Grid item xs={12} sm={12} md={2} lg={2} className="py-0 d-flex justify-content-center align-items-center" >
                              <h5>Sku</h5>
                           </Grid>
                           <Grid item xs={12} sm={12} md={4} lg={4} className="py-0 d-flex justify-content-center align-items-center" >
                              <h5>Price</h5>
                           </Grid>
                           <Grid item xs={12} sm={12} md={2} lg={2} className="py-0 d-flex justify-content-center align-items-center" >
                              {/* <h5>Shipping</h5> */}
                           </Grid>

                        </Grid>
                        <Divider className="my-20 devider" />
                        {productDetails ?
                           <Fragment
                           //  key={index}
                           >
                              <Grid container spacing={24} className="my-0">
                                 <Grid
                                    item xs={12} sm={12} md={2} lg={2}
                                    className="py-0 d-flex  justify-content-center align-items-center mb-md-0 mb-20">
                                    <Slider {...settings}>
                                       {images && images.length && images.map((image, index) => {
                                          if (index === 0) {
                                             let src = image ? local.imageUrl + image.externalUrl : "";
                                             return (
                                               <Link
                                                 to={{
                                                   pathname: "/productdetail",
                                                   search:
                                                     "?id=" + productDetails.id,
                                                 }}
                                                 className="d-flex justify-content-center align-content-center"
                                                 key={index}
                                               >
                                                 <HtmlTooltip
                                                   title={name ? name : ""}
                                                   placement="top"
                                                 >
                                                   <CardMedia
                                                     key={index}
                                                     height="140"
                                                     component="img"
                                                     image={src}
                                                     style={{
                                                       maxWidth: "200px",
                                                       maxHeight: "250px",
                                                       objectFit: "contain",
                                                     }}
                                                   />
                                                 </HtmlTooltip>
                                               </Link>
                                             );
                                          }
                                       })}
                                    </Slider>

                                 </Grid>
                                 <Grid item xs={12} sm={12} md={2} lg={2} className="py-0 d-flex justify-content-center align-items-center mb-md-0 mb-20" >
                                    <div className="text-center">
                                       <h6 className="mb-10">
                                          <Link style={{ color: 'black' }} to={{
                                             pathname: '/productdetail',
                                             search: '?id=' + productDetails.id // orderItem.productId,

                                          }} title="click here to get product details">
                                             {name}
                                          </Link>
                                       </h6>
                                    </div>
                                 </Grid>
                                 <Grid item xs={12} sm={12} md={2} lg={2} className="py-0 d-flex justify-content-center align-items-center" >
                                    <FormControl className="mb-20">
                                       <h4 style={{ fontSize: '1rem' }}>{productDetails.sku
                                          // orderItem.sku
                                       }</h4>
                                    </FormControl>
                                 </Grid>
                                 <Grid item xs={12} sm={12} md={4} lg={4} className="py-0 d-flex justify-content-center align-items-center" >
                                    <div>
                                       {productDetails.productMerchantPrices && productDetails.productMerchantPrices.map((productMerchantPrice, index) => {
                                          return <CartPricecomponent
                                             key={index}
                                             index={index}
                                             quantity={quantity}
                                             updateMerchantArr={this.updateMerchantArr}
                                             merchantArr={productDetails.merchantArray}
                                             newPrice={this.state.newPrice}
                                             detailPromotion={true}
                                             singlePrice={productDetails.productMerchantPrices.length > 1 ? false : true}
                                             productDetails={{ ...productDetails, merchantId: productDetails.merchantId ? productDetails.merchantId : "1" }}
                                             pricingDetail={productMerchantPrice}
                                             defaultProductId={productDetails.id}
                                             selectedStrength={this.selectedStrength}
                                             cartFlagUpdate={this.cartFlagUpdate}
                                             length={productDetails.productMerchantPrices.length}
                                             firstLoaded={productDetails.firstLoaded}
                                             selectedMerchant={productDetails.selectedMerchant}
                                             selectedProduct={productDetails.selectedProduct}
                                             updateOrderOnline={this.updateOrderOnline}
                                          />
                                       })}
                                    </div>
                                 </Grid>
                                 <Grid item xs={12} sm={12} md={2} lg={2} className="py-0 d-flex justify-content-center align-items-center" >
                                    <FormControl className="mb-20">
                                       <div style={{ fontSize: '0.8rem', color: '#283593', fontWeight: 'bold' }}>
                                          <Button style={{ cursor: 'auto' }} color="primary" variant="contained"
                                          // onClick={() => { this.handleOpen() }}
                                          >Add to cart
                                             {/* {orderItem.status} */}
                                          </Button>
                                       </div>

                                    </FormControl>
                                 </Grid>

                              </Grid>
                              <Divider className="my-20" />
                           </Fragment>
                           : ""}
                     </div>
                  </div>
               </Dialog>
            </div>

         </div >
      );
   }
}

const mapStateToProps = ({ ecommerce }) => {

   const { } = ecommerce;
   return {};
}

export default connect(mapStateToProps, { showAlert })(Savings);
