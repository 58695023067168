/**
 * Dasboard Routes
 */
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import IdleTimer from "react-idle-timer";

// async components
import {
  AsyncImageNavTab,
  AsyncMerchantStoreComponent,
  AsyncStoreListComponent,
  AsyncStoreBrandingComponent,
  AsyncProfileComponent,
  AsyncUserListComponent,
  AsyncGroupsComponent,
  AsyncCreatGroupComponent,
  AsynccategoriesComponent,
  AsynchierachyComponent,
  AsyncoptionsComponent,
  AsyncoptionValuesComponent,
  AsyncfeaturedProductListComponent,
  AsyncproductGroupListComponent,
  AsyncmanufaturersListComponent,
  AsyncHomeComponent,
  AsyncproductsComponent,
  AsyncCreateBoxComponent,
  AsyncContentPageListComponent,
  AsyncContentPageCreateComponent,
  AsyncContentManageBoxesComponent,
  AsyncContentImagesComponent,
  AsyncContentFilesComponent,
  AsyncCatalogueComponent,
  AsyncSignInPageComponent,
  AsyncCategoryMappingComponent,
  AsyncChildCategoryMappingComponent,
  AsyncProductCategoryMappingComponent,
  AsyncProductMappingComponent,
  AsyncAdvertisementsComponent,
  AsyncProfileSettingsComponent,
  AsyncProductOrderComponent,
  AsyncRatingsAndReviewsComponent,
  AsyncStoresComponent,
  AsyncStoreDetailsComponent,
  AsyncAnnouncementsAddComponent,
  AsyncAnnouncementsListComponent,
  AsyncCompanyListComponent,
  AsyncCompanyDetailsComponent,
  AsyncEditCompanyDetailsComponent,
  AsyncManufacturerMappingComponent,
  AsyncAreaManagerComponent,
  AsyncAreaManagerDetailsComponent,
  AsyncGroupMembershipComponent,
  AsyncCreateProductMappingComponent,
  AsyncProductPriceUpdateComponent,
  AsyncEditProductMappingComponent,
  AsyncMapProductMappingComponent,
  AsyncUnMapProductMappingComponent,
  AsyncViewProductMappingComponent,
  AsyncEditManufacturerMappingComponent,
  AsyncMapManufacturerMappingComponent,
  AsyncUnMapManufacturerMappingComponent,
  AsyncPlacedOrdersComponent,
  AsyncOrdersHistoryPracticeComponent,
  AsyncConfigProductMappingComponent,
  AsyncOrderConfigurationComponent,
  AsyncJobListComponent,
  AsyncJobOrderHistoryListComponent,
  AsyncJobOrderHistoryCompanyListComponent,
  AsyncOrderDiscrepancyListComponent,
  AsyncUsersComponent,
  AsyncUserDetailsComponent,
  AsyncEditUserDetailsComponent,
  AsyncGroupComponent,
  AsyncGroupOptionsComponent,
  AsyncGroupAreaComponent,
  AsyncPointsComponent,
  AsyncPointsDetailComponent,
  AsyncWhiteLabelListComponent,
  AsyncWhiteLabelDetailsComponent,
  AsyncEditWhiteLabelDetailsComponent,
  AsyncJobAdminListComponent,
  AsyncHistorySummaryComponent,
  AsyncAdminVendorComponent,
  AsyncOrderStatusComponent,
  AsyncMappedProductsComponent,
  AsyncCustomProductComponent,
  AsyncAlternateProductsComponent,
  AsyncAddSavingsAlternateProductsComponent,
  AsyncAddBeforeYouBuyAlternateProductsComponent,
  AsyncProgramListComponent,
  AsyncProgramCompanyComponent,
  AsyncProgramCompanyListComponent,
  AsyncProgramRewardComponent,
  AsyncSystemListComponent,
  AsyncGroupWhiteLabelComponent,
  AsyncPracticeListEditComponent,
  AsyncPracticeDetailEditComponent,
  AsyncAdminFaqComponent,
  AsyncAdminEditFAQComponent,
  AsyncPlacedOrdersGreenShippedComponent,
  AsyncCustomerListComponent,
  AsyncCustomerDetailsComponent,
  AsyncReleaseNotesComponent,
  AsyncEditReleaseNotesComponent,
} from "../../util/AsyncRoutes";

import { hideAlert, logout } from "../../actions/action";

export const WHITE_LABEL_ATTRIBUTES_LABELS = {
  BROWSER_TITLE_LABEL: "Browser Title",
  DASHBOARD_TITLE_LABEL: "Dashboard Title",
  EXCLUSIVE_PROMOTIONS_MENU_LABEL: '"Exclusive Promotions" menu',
  BUCKS_LABEL: "Bucks",
  REBATES_AND_SAVINGS_LABEL: "Rebates and Savings",
  POWER_BI_REPORT_ID_LABEL: "Power BI Report ID",
  PREFERRED_FILTER_LABEL: "Preferred Filter",
  POWER_BI_EMBED_LABEL: "Power BI Embed",
  SHARING_DATA_LABEL: "Sharing Data",
  NO_PROMOTION_DATA_LABEL: "No Promotion Data",
  REBATES_LABEL: "Rebates",
  SAVINGS_LABEL: "Savings",
  ESTIMATOR_LABEL: "Estimator",
  PARTNERS_PAGE_LABEL: "Partners Page",
  PREFERRED_PRODUCT_LABEL: "Preferred Product Label",
  PREFERRED_VENDOR_LABEL: "Preferred Vendor Label",
  GOOGLE_ANALYTICS_ID_LABEL: "Google Analytics Id Label",
};

export const WHITE_LABEL_ATTRIBUTES_IMAGES = {
  FORGOT_PASSWORD_IMAGE_URL: "Forgot Password",
  LOGO_URL: "Logo",
  REGISTER_IMAGE_URL: "Register",
  FEVICON_IMAGE_URL: "Fav Icon",
  SIGNIN_IMAGE_URL: "Sign On",
  REBATE_IMAGE_URL: "Rebate",
  PREFERRED_IMAGE_URL: "Preferred",
};

export const WHITE_LABEL_ATTRIBUTES_COLORS = {
  PRIMARY_COLOR: "Primary Color",
  SECONDARY_COLOR: "Secondary Color",
  ACTIVE_COLOR: "Active Color",
};

export const WHITE_LABEL_ATTRIBUTES_EXTRA = {
  EXCLUSIVE_PROMOTIONS_MENU_DISABLE: 'Disable "Exclusive Promotions" Menu',
  REBATES_DISABLE: "Hide Rebates Table From Savings Page",
  MISSED_SAVINGS_DASHBOARD_DISABLE: "Missed Saving Dashboard Disable",
  SAVINGS_DASHBOARD_DISABLE: "Saving Dashboard Disable",
};

class AdminPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loggedin: true };
    this.idleTimer = null;
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  _onActive(e) { }

  _onIdle(e) {
    if (this.props.location.pathname == "/admin") {
    } else {
      this.props.logout().then((res) => {
        sessionStorage.clear();
        window.location.replace(process.env.PUBLIC_URL + "/admin");
      });
    }
  }

  render() {
    const { match, alertType } = this.props;
    return (
      <IdleTimer
        ref={(ref) => {
          this.idleTimer = ref;
        }}
        element={document}
        onActive={this.onActive}
        onIdle={this.onIdle}
        timeout={1000 * 60 * 15}
      >
        <div className="dashboard-wrapper">
          {this.state.loggedin ? (
            <Switch>
              <Redirect
                exact
                from={`${match.url}/shop`}
                to={`${match.url}/home`}
              />

              {/* <Redirect exact from={`${match.url}/shop`} to={`${match.url}/categoryMapping`} /> */}
              <Route
                path={`${match.url}/categoryMapping/products/mapped`}
                component={AsyncMappedProductsComponent}
              />
              <Route
                path={`${match.url}/categoryMapping/childs`}
                component={AsyncChildCategoryMappingComponent}
              />
              <Route
                path={`${match.url}/categoryMapping/products`}
                component={AsyncProductCategoryMappingComponent}
              />

              <Route
                path={`${match.url}/categoryMapping`}
                component={AsyncCategoryMappingComponent}
              />

              <Route
                path={`${match.url}/productMapping`}
                component={AsyncProductMappingComponent}
              />

              <Route
                path={`${match.url}/orders/compare`}
                component={AsyncProductOrderComponent}
              />
              <Route
                path={`${match.url}/orders/placed`}
                component={AsyncPlacedOrdersComponent}
              />
              <Route
                path={`${match.url}/orders/greenshipping`}
                component={AsyncPlacedOrdersGreenShippedComponent}
              />

              <Route
                path={`${match.url}/merchantStore`}
                component={AsyncMerchantStoreComponent}
              />
              <Route
                path={`${match.url}/contentFiles`}
                component={AsyncContentFilesComponent}
              />
              <Route
                path={`${match.url}/contentManageBoxes`}
                component={AsyncContentManageBoxesComponent}
              />
              <Route
                path={`${match.url}/contentPageCreate`}
                component={AsyncContentPageCreateComponent}
              />
              <Route
                path={`${match.url}/contentPageList`}
                component={AsyncContentPageListComponent}
              />
              <Route
                path={`${match.url}/contentImage`}
                component={AsyncContentImagesComponent}
              />
              <Route
                path={`${match.url}/createBox`}
                component={AsyncCreateBoxComponent}
              />
              <Route
                path={`${match.url}/home`}
                component={AsyncHomeComponent}
              />
              <Route
                path={`${match.url}/storeList`}
                component={AsyncStoreListComponent}
              />
              <Route
                path={`${match.url}/storeBranding`}
                component={AsyncStoreBrandingComponent}
              />
              <Route
                path={`${match.url}/catalogue`}
                component={AsyncCatalogueComponent}
              />
              <Route
                path={`${match.url}/profile`}
                component={AsyncProfileComponent}
              />
              <Route
                path={`${match.url}/userList`}
                component={AsyncUserListComponent}
              />
              <Route
                path={`${match.url}/groups`}
                component={AsyncGroupsComponent}
              />
              <Route
                path={`${match.url}/creatGroup`}
                component={AsyncCreatGroupComponent}
              />
              <Route
                path={`${match.url}/categories`}
                component={AsynccategoriesComponent}
              />
              <Route
                path={`${match.url}/hierachy`}
                component={AsynchierachyComponent}
              />
              <Route
                path={`${match.url}/options`}
                component={AsyncoptionsComponent}
              />
              <Route
                path={`${match.url}/optionValues`}
                component={AsyncoptionValuesComponent}
              />
              <Route
                path={`${match.url}/featuredProductList`}
                component={AsyncfeaturedProductListComponent}
              />
              <Route
                path={`${match.url}/manufaturersList`}
                component={AsyncmanufaturersListComponent}
              />

              <Route
                exact
                path={`${match.url}/products`}
                component={AsyncproductsComponent}
              />

              <Route
                path={`${match.url}/products/product-update`}
                component={AsyncProductPriceUpdateComponent}
              />

              <Route
                path={`${match.url}/manufacturerMapping`}
                component={AsyncManufacturerMappingComponent}
              />
              {/* <Route
              path={`${match.url}/productMappingSingle`}
              component={AsyncProductMappingSingleComponent}
            /> */}

              <Route
                path={`${match.url}/announcements/add`}
                component={AsyncAnnouncementsAddComponent}
              />
              <Route
                path={`${match.url}/announcements/list`}
                component={AsyncAnnouncementsListComponent}
              />

              <Route
                path={`${match.url}/advertisements`}
                component={AsyncAdvertisementsComponent}
              />
              <Route
                path={`${match.url}/profileSettings`}
                component={AsyncProfileSettingsComponent}
              />
              <Route
                path={`${match.url}/ratingsAndReviews`}
                component={AsyncRatingsAndReviewsComponent}
              />
              <Route
                path={`${match.url}/config`}
                component={AsyncConfigProductMappingComponent}
              />
              <Route
                path={`${match.url}/stores`}
                component={AsyncStoresComponent}
              />
              <Route
                path={`${match.url}/storeDetails`}
                component={AsyncStoreDetailsComponent}
              />
              <Route
                path={`${match.url}/whitelabel`}
                component={AsyncWhiteLabelListComponent}
              />
              <Route
                path={`${match.url}/whiteLabelDetails`}
                component={AsyncWhiteLabelDetailsComponent}
              />
              <Route
                path={`${match.url}/edit/whiteLabelDetails`}
                component={AsyncEditWhiteLabelDetailsComponent}
              />
              <Route
                path={`${match.url}/customer`}
                component={AsyncCustomerListComponent}
              />
              <Route
                path={`${match.url}/customerDetails`}
                component={AsyncCustomerDetailsComponent}
              />
              <Route
                path={`${match.url}/practice`}
                component={AsyncCompanyListComponent}
              />
              <Route
                path={`${match.url}/practiceDetails`}
                component={AsyncCompanyDetailsComponent}
              />
              <Route
                path={`${match.url}/edit/practiceDetails`}
                component={AsyncEditCompanyDetailsComponent}
              />
              <Route
                path={`${match.url}/users`}
                component={AsyncUsersComponent}
              />
              <Route
                path={`${match.url}/userDetails`}
                component={AsyncUserDetailsComponent}
              />
              <Route
                path={`${match.url}/edit/userDetails`}
                component={AsyncEditUserDetailsComponent}
              />

              <Route
                path={`${match.url}/group/areaManager`}
                component={AsyncAreaManagerComponent}
              />

              <Route
                path={`${match.url}/areaManagerDetails`}
                component={AsyncAreaManagerDetailsComponent}
              />
              <Route
                path={`${match.url}/group/membership`}
                component={AsyncGroupMembershipComponent}
              />

              <Route
                path={`${match.url}/productMappingSingle/edit`}
                component={AsyncEditProductMappingComponent}
              />

              {/* imageTabadded */}
              <Route
                path={`${match.url}/productMappingSingle/image`}
                component={AsyncImageNavTab}
              />
              {/* imageTabadded */}

              <Route
                path={`${match.url}/productMappingSingle/create`}
                component={AsyncCreateProductMappingComponent}
              />

              <Route
                path={`${match.url}/productMappingSingle/custom`}
                component={AsyncCustomProductComponent}
              />
              <Route
                path={`${match.url}/productMappingSingle/map`}
                component={AsyncMapProductMappingComponent}
              />
              <Route
                path={`${match.url}/productMappingSingle/un-map`}
                component={AsyncUnMapProductMappingComponent}
              />
              <Route
                path={`${match.url}/productMappingSingle/view`}
                component={AsyncViewProductMappingComponent}
              />
              <Route
                path={`${match.url}/productMappingSingle/alternate/addSavings`}
                component={AsyncAddSavingsAlternateProductsComponent}
              />

              <Route
                path={`${match.url}/productMappingSingle/alternate/addBeforeYouBuy`}
                component={AsyncAddBeforeYouBuyAlternateProductsComponent}
              />

              <Route
                path={`${match.url}/productMappingSingle/alternate`}
                component={AsyncAlternateProductsComponent}
              />
              <Route
                path={`${match.url}/manufacturerMappingSingle/edit`}
                component={AsyncEditManufacturerMappingComponent}
              />
              <Route
                path={`${match.url}/manufacturerMappingSingle/map`}
                component={AsyncMapManufacturerMappingComponent}
              />
              <Route
                path={`${match.url}/manufacturerMappingSingle/un-map`}
                component={AsyncUnMapManufacturerMappingComponent}
              />

              <Route
                path={`${match.url}/orders/practice/history`}
                component={AsyncOrdersHistoryPracticeComponent}
              />

              <Route
                path={`${match.url}/orders/configuration`}
                component={AsyncOrderConfigurationComponent}
              />

              <Route
                path={`${match.url}/job/list`}
                component={AsyncJobListComponent}
              />

              <Route
                path={`${match.url}/job/history`}
                component={AsyncJobAdminListComponent}
              />

              <Route
                path={`${match.url}/job/lastOrderHistory/detail`}
                component={AsyncJobOrderHistoryCompanyListComponent}
              />

              <Route
                path={`${match.url}/job/lastOrderHistory`}
                component={AsyncJobOrderHistoryListComponent}
              />

              <Route
                path={`${match.url}/orders/discrepancy`}
                component={AsyncOrderDiscrepancyListComponent}
              />
              <Route
                path={`${match.url}/group/list`}
                component={AsyncGroupComponent}
              />
              <Route
                path={`${match.url}/group-options/list`}
                component={AsyncGroupOptionsComponent}
              />
              <Route
                path={`${match.url}/group-area/list`}
                component={AsyncGroupAreaComponent}
              />
              <Route
                path={`${match.url}/orders/points`}
                component={AsyncPointsComponent}
              />
              <Route
                path={`${match.url}/orders/points-detail`}
                component={AsyncPointsDetailComponent}
              />
              <Route
                path={`${match.url}/orders/history`}
                component={AsyncHistorySummaryComponent}
              />
              <Route
                path={`${match.url}/order-status`}
                component={AsyncOrderStatusComponent}
              />
              <Route
                path={`${match.url}/vendors`}
                component={AsyncAdminVendorComponent}
              />
              <Route
                path={`${match.url}/program/company-add`}
                component={AsyncProgramCompanyComponent}
              />
              <Route
                path={`${match.url}/program/company-list`}
                component={AsyncProgramCompanyListComponent}
              />
              <Route
                path={`${match.url}/program/reward`}
                component={AsyncProgramRewardComponent}
              />
              <Route
                path={`${match.url}/program`}
                component={AsyncProgramListComponent}
              />

              {/* <Route
                path={`${match.url}/system-configuration`}
                component={AsyncSystemListComponent}
              /> */}

              <Route
                path={`${match.url}/faq`}
                component={AsyncAdminFaqComponent}
              />
              <Route
                path={`${match.url}/edit/faq/:id?`}
                component={AsyncAdminEditFAQComponent}
              />
              <Route
                path={`${match.url}/group/white-label`}
                component={AsyncGroupWhiteLabelComponent}
              />
              <Route
                path={`${match.url}/practice-edit/list`}
                component={AsyncPracticeListEditComponent}
              />
              <Route
                path={`${match.url}/practice-edit/detail`}
                component={AsyncPracticeDetailEditComponent}
              />
              <Route
                exact
                path={`${match.url}/release-notes/edit`}
                component={AsyncEditReleaseNotesComponent}
              />
              <Route
                path={`${match.url}/release-notes/edit/:id`}
                component={AsyncEditReleaseNotesComponent}
              />
              <Route
                exact
                path={`${match.url}/release-notes`}
                component={AsyncReleaseNotesComponent}
              />
            </Switch>
          ) : (
            <Switch>
              <Redirect exact from={`${match.url}/`} to={`/admin`} />
              <Route path={`/admin`} component={AsyncSignInPageComponent} />
            </Switch>
          )}
          {/* {!this.state.loggedin
          ? this.props.history.push("/admin")
          : this.props.history.push("/")} */}
          <SweetAlert
            success={alertType === "success"}
            error={alertType === "error"}
            title=""
            confirmBtnText="Ok"
            confirmBtnBsStyle="warning"
            className="iron-alert-box"
            show={this.props.showAlert}
            onConfirm={this.props.hideAlert}
            onCancel={this.props.hideAlert}
            closeOnClickOutside
          >
            {this.props.alertMessage}
          </SweetAlert>
        </div>
      </IdleTimer>
    );
  }
}

const mapStateToProps = ({ appSettings }) => {
  const { showAlert, alertMessage, selectedLocale, alertType } = appSettings;
  return { showAlert, alertMessage, selectedLocale, alertType };
};

export default connect(mapStateToProps, {
  hideAlert,
  logout,
})(AdminPanel);
