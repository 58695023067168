import React, { useState, useEffect } from "react";
import "./Menu.scss";

const Menu = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        // Scrolling down
        setIsVisible(false);
      } else {
        // Scrolling up
        setIsVisible(true);
      }
      lastScrollY = window.scrollY; // Update lastScrollY
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <nav className={`menu ${isVisible ? "menu--visible" : "menu--hidden"}`}>
      <ul className="menu__items">
        <MenuItem label="Home" />
        <MenuItem label="Products" />
        <MenuItem label="About" />
        <MenuItem label="Contact" />
      </ul>
    </nav>
  );
};

const MenuItem = ({ label }) => {
  return <li className="menu__item">{label}</li>;
};

export default Menu;
