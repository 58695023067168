/**
 * Fixed header component
 */
/* eslint-disable */
import React from 'react';
import Grid from "@material-ui/core/Grid";
import SearchBox from "./SearchBox";
import HeaderDiv from "./HeaderDiv";
// components
import HeaderMenu from "./HeaderMenu";
import SidebarMenu from '../sidebar';
import AppConfig from '../../../constants/AppConfig';

import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { getCompanyDefaultGroup } from "../../../../Authorization";
import PointBuckSvgComponent from "../../../assets/svgcomponents/PointBuckSvgComponent";

class FixedHeader extends React.Component {

    constructor(props) {
        super(props);
        this.confirmationDialog = React.createRef();
        this.state = {
            anchorEl: null,
            balance: "$0.00",
            showBucks: false,
            links: this.props.links,
            companyDefaultGroup: null
        };
    }

    componentDidMount() {
        this.setState({ companyDefaultGroup: getCompanyDefaultGroup() });
    }

    redirectToHome = () => {
        const redirectUrl = sessionStorage.getItem("redirectUrl");
        this.props.history.push(redirectUrl);
    }
    numberWithCommas = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    render() {
        const { groupAreaManager, balance, showBucks, links, points, showPoints, groupModel, appAttributes } = this.props;
        return (
            <div className="iron-fixed-header second-header bg-primary" style={{width: '100%'}}>
                <div className="container" style={{margin: 'auto'}}>
                    {groupAreaManager != null
                        ?
                        groupAreaManager ?
                            <Grid container spacing={0} >
                                <Grid item xs={3} sm={3} md={1} lg={1} xl={1} className="d-flex justify-content-start align-items-center" >
                                    <div className="iron-app-logo ">
                                        <a onClick={() => { this.redirectToHome() }}>
                                            <img src={appAttributes && appAttributes.LOGO_URL ? appAttributes.LOGO_URL : AppConfig.AppLogo} alt="header-logo" style={{ width: "25%" }} />
                                        </a>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={5} >
                                    <HeaderMenu history={this.props.history} location={this.props.location} links={links} isFixedHeader={true} />
                                    <SidebarMenu history={this.props.history} location={this.props.location} links={links} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} className="hidden-desktop" >
                                    <div className="iron-header-widgets d-flex justify-content-center align-items-center hidden-desktop mb-8 mt-6">
                                        <HeaderDiv history={this.props.history} groupAreaManager={groupAreaManager} location={this.props.location} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={4} >
                                    <div className="text-center">
                                        <h4 style={{ color: "white", paddingTop: "8px" }}>{appAttributes && appAttributes.DASHBOARD_TITLE_LABEL ? appAttributes.DASHBOARD_TITLE_LABEL : "Dashboard"}</h4>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2} className="hidden-mobile">
                                    <div className="iron-header-widgets d-flex justify-content-center align-items-center mb-8 mt-6">
                                        <HeaderDiv history={this.props.history} groupAreaManager={groupAreaManager} location={this.props.location} />
                                    </div>
                                </Grid>
                            </Grid>
                            :
                            <Grid container spacing={0} >
                                <Grid item xs={3} sm={3} md={1} lg={1} xl={1} className="d-flex justify-content-start align-items-center" >
                                    <div className="iron-app-logo ">
                                        <a onClick={() => { this.redirectToHome() }}>
                                            <img src={appAttributes && appAttributes.LOGO_URL ? appAttributes.LOGO_URL : AppConfig.AppLogo} alt="header-logo" style={{ width: "45%" }} />
                                        </a>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={6} md={2} lg={2} xl={2} className="hidden-desktop" >
                                    <div className="iron-header-widgets d-flex justify-content-center align-items-center hidden-desktop mb-8 mt-6">
                                        <HeaderDiv history={this.props.history} location={this.props.location} groupAreaManager={groupAreaManager} />
                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={3} md={6} lg={6} xl={5} >
                                    <div className="">
                                        <HeaderMenu history={this.props.history} location={this.props.location} links={links} isFixedHeader={true} />
                                        <SidebarMenu history={this.props.history} location={this.props.location} links={links} />
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={2} className="hidden-smaller-desktop">
                                    {groupModel ?
                                        groupModel.isPSIMemeber || (showPoints && showBucks) ?
                                            <div className="point-buck">
                                                {points.totalAvailable == 1 ? <Link className="point" to="/points">
                                                    <p><b>{this.numberWithCommas(
                                                        points.totalPoints
                                                    )}</b></p>
                                                    <span><b>Points</b></span>
                                                </Link> : <a className="point" >
                                                    <p>Points</p>
                                                    <span><b>Coming Soon</b></span>
                                                </a>}
                                                <div>
                                                    <PointBuckSvgComponent height="52" width="44" active="var(--active)" />
                                                    {/* <img src={pb} alt="" style={{ height: '52px' }} /> */}
                                                </div>
                                                <Link className="buck" to="/transaction">
                                                    <p><b> {balance}</b></p>
                                                    <span><b>{appAttributes && appAttributes.BUCKS_LABEL ? appAttributes.BUCKS_LABEL : "Bucks"} </b></span>
                                                    {/* <span><b>{this.state.companyDefaultGroup ? this.state.companyDefaultGroup.groupName : null} Bucks</b></span> */}
                                                </Link>
                                            </div>
                                            : showPoints ?
                                            <div className="point-buck half">
                                                {points.totalAvailable == 1 ? <Link className="point" to="/points">
                                                    <p><b>{this.numberWithCommas(
                                                        points.totalPoints
                                                    )}</b></p>
                                                    <span><b>Points</b></span>
                                                </Link> : <a className="point" >
                                                    <p>Points</p>
                                                    <span><b>Coming Soon</b></span>
                                                </a>}
                                            </div>
                                            : showBucks ?
                                            <div className="point-buck half">
                                                <Link className="buck" to="/transaction">
                                                    <p><b> {balance}</b></p>
                                                    <span><b>{appAttributes && appAttributes.BUCKS_LABEL ? appAttributes.BUCKS_LABEL : "Bucks"} </b></span>
                                                </Link>
                                            </div>
                                            : <div className="point-buck">
                                                {points.totalAvailable == 1 ? <Link className="only-points" to="/points">
                                                    <p><b>{this.numberWithCommas(
                                                        points.totalPoints
                                                    )}</b></p>
                                                    <span><b>Points</b></span>
                                                </Link> : <a className="point" >
                                                    <p>Points</p>
                                                    <span><b>Coming Soon</b></span>
                                                </a>}
                                            </div> : ""}
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} className="hidden-search-smaller-desktop ">
                                    <div className="srch-icon">
                                        <SearchBox history={this.props.history} location={this.props.location} searchStr={this.props.searchStr} onSearchChange={this.props.onSearchChange}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} className="hidden-mobile">
                                    <div className="iron-header-widgets d-flex justify-content-center align-items-center mb-8 mt-6">
                                        <HeaderDiv history={this.props.history} location={this.props.location} groupAreaManager={groupAreaManager} />
                                    </div>
                                </Grid>
                            </Grid>
                        : ""
                    }

                </div>
            </div>
        );
    }
}
const mapStateToProps = ({ appSettings }) => {
    const { appAttributes } = appSettings
    return { appAttributes };
}

export default connect(mapStateToProps, {
})(FixedHeader);
